// These helpers are calling this template's own server-side routes
// so, they are not directly calling Marketplace API or Integration API.
// You can find these api endpoints from 'server/api/...' directory

import appSettings from '../config/settings';
import { types as sdkTypes, transit } from './sdkLoader';
import Decimal from 'decimal.js';

export const apiBaseUrl = marketplaceRootURL => {
  const port = process.env.REACT_APP_DEV_API_SERVER_PORT;
  const useDevApiServer = process.env.NODE_ENV === 'development' && !!port;

  // In development, the dev API server is running in a different port
  if (useDevApiServer) {
    return `http://localhost:${port}`;
  }

  // Otherwise, use the given marketplaceRootURL parameter or the same domain and port as the frontend
  return marketplaceRootURL ? marketplaceRootURL.replace(/\/$/, '') : `${window.location.origin}`;
};

// Application type handlers for JS SDK.
//
// NOTE: keep in sync with `typeHandlers` in `server/api-util/sdk.js`
export const typeHandlers = [
  // Use Decimal type instead of SDK's BigDecimal.
  {
    type: sdkTypes.BigDecimal,
    customType: Decimal,
    writer: v => new sdkTypes.BigDecimal(v.toString()),
    reader: v => new Decimal(v.value),
  },
];

const serialize = data => {
  return transit.write(data, { typeHandlers, verbose: appSettings.sdk.transitVerbose });
};

const deserialize = str => {
  return transit.read(str, { typeHandlers });
};

const methods = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
};

// If server/api returns data from SDK, you should set Content-Type to 'application/transit+json'
const request = (path, options = {}) => {
  const url = `${apiBaseUrl()}${path}`;
  const { credentials, headers, body, ...rest } = options;

  // If headers are not set, we assume that the body should be serialized as transit format.
  const shouldSerializeBody =
    (!headers || headers['Content-Type'] === 'application/transit+json') && body;
  const bodyMaybe = shouldSerializeBody ? { body: serialize(body) } : {};

  const fetchOptions = {
    credentials: credentials || 'include',
    // Since server/api mostly talks to Marketplace API using SDK,
    // we default to 'application/transit+json' as content type (as SDK uses transit).
    headers: headers || { 'Content-Type': 'application/transit+json' },
    ...bodyMaybe,
    ...rest,
  };

  return window.fetch(url, fetchOptions).then(res => {
    const contentTypeHeader = res.headers.get('Content-Type');
    const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

    if (res.status >= 400) {
      return res.json().then(data => {
        let e = new Error();
        e = Object.assign(e, data);

        throw e;
      });
    }
    if (contentType === 'application/transit+json') {
      return res.text().then(deserialize);
    } else if (contentType === 'application/json') {
      return res.json();
    }
    return res.text();
  });
};

// Keep the previous parameter order for the post method.
// For now, only POST has own specific function, but you can create more or use request directly.
const post = (path, body, options = {}) => {
  const requestOptions = {
    ...options,
    method: methods.POST,
    body,
  };

  return request(path, requestOptions);
};

const get = (path, queryParams = {}, options = {}) => {
  // Construct the full URL with query parameters
  const queryString = new URLSearchParams(queryParams).toString();
  const url = `${path}${queryString ? `?${queryString}` : ''}`;

  // Set method as GET and pass other options to the request function
  const requestOptions = {
    ...options,
    method: methods.GET,
  };

  return request(url, requestOptions);
};

// Fetch transaction line items from the local API endpoint.
//
// See `server/api/transaction-line-items.js` to see what data should
// be sent in the body.
export const transactionLineItems = body => {
  return post('/api/transaction-line-items', body);
};

// Initiate a privileged transaction.
//
// With privileged transitions, the transactions need to be created
// from the backend. This endpoint enables sending the order data to
// the local backend, and passing that to the Marketplace API.
//
// See `server/api/initiate-privileged.js` to see what data should be
// sent in the body.
export const initiatePrivileged = body => {
  return post('/api/initiate-privileged', body);
};

// Transition a transaction with a privileged transition.
//
// This is similar to the `initiatePrivileged` above. It will use the
// backend for the transition. The backend endpoint will add the
// payment line items to the transition params.
//
// See `server/api/transition-privileged.js` to see what data should
// be sent in the body.
export const transitionPrivileged = body => {
  return post('/api/transition-privileged', body);
};

// Create user with identity provider (e.g. Facebook or Google)
//
// If loginWithIdp api call fails and user can't authenticate to Marketplace API with idp
// we will show option to create a new user with idp.
// For that user needs to confirm data fetched from the idp.
// After the confirmation, this endpoint is called to create a new user with confirmed data.
//
// See `server/api/auth/createUserWithIdp.js` to see what data should
// be sent in the body.
export const createUserWithIdp = body => {
  return post('/api/auth/create-user-with-idp', body);
};

export const translateText = body => {
  return post('/api/translate', body);
};

export const translateListingTitle = body => {
  return post('/api/translateListingTitle', body);
};
export const translateListingDescription = body => {
  return post('/api/translateListingDescription', body);
};

export const addPersonToNewsLetter = body => {
  return post('/api/addPerson', body);
};

export const searchForPersonInNewsletter = email => {
  const queryParams = { email };

  return get('/api/searchPersons', queryParams);
};
export const deleteException = body => {
  return post('/api/delete-exception', body);
};

export const fetchListing = body => {
  return post('/api/fetch-listing', body);
};
export const copyOwnListing = body => {
  return post('/api/copy-listing', body);
};

export const fetchBookingsFromDb = body => {
  return get('/api/fetch-bookings', body);
};
export const fetchBookingsAndWorkshops = body => {
  return get('/api/fetch-bookings-workshop', body);
};
export const searchListingsWhenDatesAreSelected = body => {
  return post('/api/search-listing', body);
};
export const createCheckoutSession = body => {
  return post('/api/create-checkout-session', body);
};
export const initiatePaymentWithPaypalBancontact = body => {
  return post('/api/paypal-bancontact', body);
};
export const createStripePaymentMethod = body => {
  return post('/api/create-payment-intent', body);
};
export const createTransactionUsingVenue = body => {
  return post('/api/venue-transaction', body);
};
export const cancelMembership = body => {
  return post('/api/cancel-subscription', body);
};

export const cancelTransitionApi = body => {
  return post('/api/cancel-before-start', body);
};
export const rescheduleTxApi = body => {
  return post('/api/reschedule-tx', body);
};

export const createAffiliationViaApi = body => {
  return post('/api/create-affiliation', body);
};

export const puchaseGiftCardViaApi = body => {
  return post('/api/purchase-giftcard', body);
};

export const fetchUserVouchersApi = body => {
  return post('/api/fetch-vouchers', body);
};
export const fetchUserFromApi = body => {
  return post('/api/fetch-user', body);
};

export const updatePerson = body => {
  return post('/api/updatePerson', body);
};
