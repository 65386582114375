const AFFILIATION_CHECKS = ['newborn_external', 'newborn', 'bambino', 'bambino_quarterly'];
const MEMBERSHIP_CHECKS = ['newborn', 'bambino', 'bambino_quarterly'];
const MEMBERSHIP_BAMBINO_CHECKS = ['bambino', 'bambino_quarterly'];
const EXTERNAL_NEWBORN_ONLY_CHECKS = ['newborn_external'];
export const checkAffiliation = user => {
  const { attributes } = user || {};
  const { profile } = attributes || {};
  const { metadata } = profile || {};
  const { current_period_end, membership, plan } = metadata || {};
  if (!current_period_end) return false;
  const subScriptionEndDate = new Date(current_period_end * 1000);
  const isEndDateInPast = subScriptionEndDate < new Date();
  const isSubscriptionActive = !isEndDateInPast;
  // console.log('checkAffiliation', plan, isSubscriptionActive);
  return AFFILIATION_CHECKS.includes(plan) && isSubscriptionActive;
};
export const checkExtrernalNewbornOnly = user => {
  const { attributes } = user || {};
  const { profile } = attributes || {};
  const { metadata } = profile || {};
  const { current_period_end, membership, plan } = metadata || {};
  if (!current_period_end) return false;
  const subScriptionEndDate = new Date(current_period_end * 1000);
  const isEndDateInPast = subScriptionEndDate < new Date();
  const isSubscriptionActive = !isEndDateInPast;
  // console.log('checkAffiliation', plan, isSubscriptionActive);
  return EXTERNAL_NEWBORN_ONLY_CHECKS.includes(plan) && isSubscriptionActive;
};
export const checkMembership = user => {
  const { attributes } = user || {};
  const { profile } = attributes || {};
  const { metadata } = profile || {};
  const { current_period_end, membership, plan } = metadata || {};
  if (!current_period_end) return false;
  const subScriptionEndDate = new Date(current_period_end * 1000);
  const isEndDateInPast = subScriptionEndDate < new Date();
  const isSubscriptionActive = !isEndDateInPast;
  return MEMBERSHIP_CHECKS.includes(plan) && isSubscriptionActive;
};
export const checkMembershipBambino = user => {
  const { attributes } = user || {};
  const { profile } = attributes || {};
  const { metadata } = profile || {};
  const { current_period_end, membership, plan } = metadata || {};
  if (!current_period_end) return false;
  const subScriptionEndDate = new Date(current_period_end * 1000);
  const isEndDateInPast = subScriptionEndDate < new Date();
  const isSubscriptionActive = !isEndDateInPast;
  return MEMBERSHIP_BAMBINO_CHECKS.includes(plan) && isSubscriptionActive;
};
